const coins = [
    {
        id:"1",
        name:"USDT",
        image:"https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
        id:"2",
        name:"USDX",
        image:"https://usdx.0xfactory.com/img/token-logo.png"
    },
    {
        id:"3",
        name:"BNB",
        image:"https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
    },
    {
        id:"4",
        name:"USDC",
        image:"https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
        id:"5",
        name:"FDUSD",
        image:"https://s2.coinmarketcap.com/static/img/coins/64x64/26081.png"
    },
]

export default coins